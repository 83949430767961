footer {
    .brand-border {
        display: none;
    }
}

header {
    .topbar {
        background-color: rgb(var(--grey-light));

        .switcher {
            p {
                color: rgb(var(--black));
            }

            .switch {
                input {
                    &:checked {
                        & + .slider {
                            border: solid 1px rgb(var(--black));
                        }
                    }
                }
            }
        }
    }
}